<template>
  <a-scene
    physics="debug: true; restitution: 0.8"
    background="color:white"
    renderer="colorManagement: true;"
    vr-mode-ui="enterARButton: #myEnterARButton"
    :fog="`type: exponential; color: #AAA; density:	${gymMode ? 0 : 0.01}`"
    gltf-model="dracoDecoderPath: https://www.gstatic.com/draco/versioned/decoders/1.5.6/;"
  >
    <!-- restitution: 0.8 -->
    <a-assets>
      <audio
        id="hit"
        :src="require('@/assets/audio/hit.mp3')"
        preload="auto"
      ></audio>
      <audio
        id="smackSwingSpeedbag"
        :src="require('@/assets/audio/smack-swing-speedbag.mp3')"
        preload="auto"
      ></audio>
      <audio
        id="swing"
        :src="require('@/assets/audio/swing-speedbag.mp3')"
        preload="auto"
      ></audio>
      <audio
        id="speedbagSmack"
        :src="require('@/assets/audio/bag-hit-speedbag.mp3')"
        preload="auto"
      ></audio>
      <audio
        id="boardHitSpeedbag"
        :src="require('@/assets/audio/board-hit-speedbag.mp3')"
        preload="auto"
      ></audio>

      <!-- <a-mixin id="handle" geometry="primitive: cylinder; height: 0.4; radius: 0.02" static-body></a-mixin> -->
    </a-assets>
    <!-- <a-assets></a-assets> -->
    <!-- <a-text
      geometry="primitive: plane; height:2; width:5"
      color="black"
      position="2 2 -7"
      rotation="0 0 0"
      align="center"
      width="30"
      material="alphaTest: 0.5"
      zOffset="-2"
      :value="'Hits: ' + hits"
    ></a-text> -->
    <!-- geometry="primitive: plane; width: 1; height: 1" -->
    <!-- material="color: white" -->
    <!-- <button style="position: absolute; z-index: 5" @click="hits++">
      hitttuyt7
    </button> -->

    <Score :hits="hits" />
    <Environment :gymMode="gymMode" />
    <Pear />

    <!-- <Hanging /> -->

    <!-- <Standing /> -->

    <!-- <Standing /> -->

    <!-- <a-box posit- -->
    <!-- <a-box position="0 0 2" constraint="target: #other-box;" dynamic-body /> -->

    <!-- <a-entity oculus-touch-controls="hand: left"
      ><a-sphere
        radius="0.1"
        static-body
        @collide="hits++"
        visible="false"
      ></a-sphere
    ></a-entity>
    <a-entity oculus-touch-controls="hand: right">
      <a-sphere
        radius="0.1"
        static-body
        @collide="hits++"
        visible="false"
      ></a-sphere>
    </a-entity> -->
    <a-entity
      id="leftHand"
      hand-controls="hand: left; handModelStyle: lowPoly; color: #ffcccc"
      wasd-controls
      ><a-sphere
        id="leftHandCollider"
        static-body
        @collide="handHitBag($event, 'left')"
        :sound="`src: #hit;`"
        radius="0.1"
        visible="false"
      ></a-sphere
    ></a-entity>
    <a-entity
      id="rightHand"
      hand-controls="hand: right; handModelStyle: lowPoly; color: #ffcccc"
    >
      <a-sphere
        id="rightHandCollider"
        radius="0.1"
        static-body
        @collide="handHitBag($event, 'right')"
        :sound="`src: #hit;`"
        visible="false"
      ></a-sphere>
    </a-entity>

    <a-entity camera position="0 0.15 0.2"> </a-entity>

    <!-- <button v-show="gameStarted" id="EnterVrGym" class="enter-vr" :disabled="!isHeadsetConnected">
      <span v-if="isHeadsetConnected">Enter VR</span>
      <span v-else>Connect a compatible headset</span>
    </button> -->

    <!-- <h1 class="toggle" @click="gymMode = !gymMode">TOGGLE</h1> -->

    <button v-show="false" ref="enter-vr" id="myEnterARButton"></button>

    <!-- <a-sky color="#ECECEC"></a-sky> -->
  </a-scene>
</template>

<script>
import Standing from "./bags/Standing";
import Pear from "./bags/Pear";
import Hanging from "./bags/Hanging";
import Environment from "./Environment";
import Score from "./Score";

export default {
  name: "Scene",
  props: ["gymMode", "gameStarted"],
  mounted() {
    // this.setDracoLoader();
  },
  data() {
    return { hits: 0 };
  },
  watch: {
    gameStarted() {
      if (this.gameStarted) {
        this.$refs["enter-vr"].click();
      }
    },
  },
  computed: {
    // isHeadsetConnected() {
    //   return AFRAME.utils.device.checkHeadsetConnected();
    // },
  },
  methods: {
    testSDK() {
      console.log(heyVR);
      heyVR.leaderboard.postScore("HitsPM", Math.floor(Math.random() * 1000));
      heyVR.leaderboard.get("HitsPM", 10).then((res) => {
        console.log(res);
      });
      // console.log(heyVR.leaderboard.get("HitsPM", 10));
    },
    handHitBag(e, hand) {
      // const bag = e.target;

      // const force = Math.abs(e.detail.contact.getImpactVelocityAlongNormal());

      // // You can adjust this threshold based on the desired sensitivity
      // const punchStrengthThreshold = 0.001;

      // const volume = Math.min(force / 2, 1);

      // if (force > punchStrengthThreshold) {
      //   const volume = Math.min(force / 2, 1);
      //   // bag.components.sound.data.volume = volume;
      //   console.log(force);
      //   bag.setAttribute("sound", "volume", force * 1.2);

      //   console.log(e.target.components.sound.data.volume);
      //   bag.components.sound.playSound();
      // }
      this.hits++;
    },
    // setDracoLoader: () => {
    //   // Instantiate a loader
    //   var dracoLoader = new AFRAME.THREE.DRACOLoader();
    //   // Specify path to a folder containing WASM/JS decoding libraries.
    //   dracoLoader.setDecoderPath(
    //     "https://www.gstatic.com/draco/versioned/decoders/1.5.6/"
    //   );
    //   // Optional: Pre-fetch Draco WASM/JS module.
    //   // dracoLoader.preload();
    //   // Get a reference to the component we want to extend.
    //   var gltfModel = AFRAME.components["gltf-model"],
    //     gltfModelComponent = gltfModel.Component;
    //   gltfModelComponent.prototype.init = function (e) {
    //     // Replace the TouchMove event handler...
    //     this.model = null;
    //     this.loader = new THREE.GLTFLoader();
    //     if (dracoLoader) {
    //       this.loader.setDRACOLoader(dracoLoader);
    //     }
    //   };
    // },
  },
  components: { Hanging, Standing, Pear, Environment, Score },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.toggle {
  position: absolute;
  top: 40px;
  right: 0;

  z-index: 5;
}
.enter-vr {
  // display: none;
  background: rgba(255, 255, 255, 0.638);
  border: 3px solid black;
  box-shadow: 0px 0px 15px white;
  color: black;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  // transform: translate(-50%, -50%);
  z-index: 5;
  // text-decoration: none;
  font-size: 2rem;
  font-family: "capture-it";

  &:disabled {
    font-size: 2rem;
  }
}
#myEnterARButton {
  display: none;
}
</style>
