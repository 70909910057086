import Vue from 'vue';
import App from './App.vue';
// import LoadScript from 'vue-plugin-load-script';
// Vue.use(LoadScript);
// Vue.loadScript('aframe')
//   .then(() => {
//     // Script is loaded, do something
//   })
//   .catch(() => {
//     // Failed to fetch script
//   });
// require('aframe');
require('./assets/js/physics_v2.js');
require('./assets/js/leaderboard.js');
require('./assets/js/canvas-material.js');
require('./assets/js/canvas-text-v2.js');
// if (process.env.NODE_ENV === 'development') {
  // const heyVR = require('./assets/js/heyvrsdk-local.js');
// }


Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'a-scene',
  'a-asset-item',
  'a-image',
  'a-curvedimage',
  'a-assets',
  'a-text',
  'a-plane',
  'a-sphere',
  'a-cylinder',
  'a-rounded',
  'a-light',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-cursor',
  'a-gltf-model',
  'a-triangle',
  'a-cubemap',
];

new Vue({
  render: (h) => h(App),
}).$mount('#app');
