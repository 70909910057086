<template>
  <a-entity class="hanging-bag" position="0 0 -0.45">
    <a-box
      rotation="0 0 0"
      position="0 4.3 0"
      color="red"
      class="other-box"
      radius="0.02"
      visible="false"
      static-body
    ></a-box>

    <!-- <a-gltf-model
      src="objects/heavy-bag.glb"
      constraint="shape: cylinder; pivot: 0 1.5 0; targetPivot: 0 -0.7 0; axis: 1 0 1; target: #other-box; type: pointToPoint"
      position="0 0.5 0"
    ></a-gltf-model> -->

    <a-cylinder
      dynamic-body="mass: 10; linearDamping: 0.1; angularDamping: 1"
      constraint="pivot: 0 2.9 0; targetPivot: 0 0 0; axis: 0 0 0; target: .other-box; type: pointToPoint"
      position="0 1.0 0"
      color="green"
      opacity="0"
      wireframe="false"
      height="1.55"
      radius="0.4"
    >
      <!-- @collide="bagHit"
      :sound="`src: #hit;`" -->
      <a-gltf-model
        src="objects/heavy-bag.glb"
        position="0 0 0"
        scale="0.8 0.8 0.8"
      ></a-gltf-model>
    </a-cylinder>

    <!-- <a-entity
      dynamic-body="mass: 100000"
      constraint="pivot: 0 1.5 0; targetPivot: 0 -0.7 0; axis: 1 0 1; target: #other-box; type: pointToPoint"
      position="0 2.0 0"
    >
      <a-cylinder
        color="green"
        visible="false"
        wireframe="false"
        height="2"
        radius="0.4"
      >
      </a-cylinder>
      <a-gltf-model
        src="objects/heavy-bag.glb"
        constraint="pivot: 0 1.5 0; targetPivot: 0 -0.7 0; axis: 1 0 1; target: #other-box; type: pointToPoint"
        position="0 0.5 0"
      ></a-gltf-model>
    </a-entity> -->
  </a-entity>
</template>

<script>
export default {
  name: "HangingBag",

  data() {
    return {};
  },
  methods: {
    bagHit(e) {
      // console.log(e.detail.body.el.id);

      const bag = e.target;
      const force = Math.abs(e.detail.contact.getImpactVelocityAlongNormal());

      // You can adjust this threshold based on the desired sensitivity
      const punchStrengthThreshold = 0.001;

      const volume = Math.min(force / 2, 1);

      if (force > punchStrengthThreshold) {
        const volume = Math.min(force / 2, 1);
        // bag.components.sound.data.volume = volume;
        console.log(force);
        bag.setAttribute("sound", "volume", force * 1.2);

        console.log(e.target.components.sound.data.volume);
        bag.components.sound.playSound();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>