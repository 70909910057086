<template>
  <div class="start-page">
    <button @click="testSDK">test SDK</button>
    <!-- <Scene :gymMode="gymMode" /> -->
    <!-- <h1>Area</h1> -->
    <div class="selection">
      <button class="selection__item" @click="$emit('gameModeSelected', true)">
        <h2 class="selection__item__title">GYM</h2>
        <!-- <button> -->
        <img
          class="selection__item__img"
          :src="require('@/assets/img/gym-mode.webp')"
          alt="gym-mode"
        />
        <!-- </button> -->

        <!-- <p class="selection__item__description">
          Gym mode is a more realistic experience. You will be able to move
          around the room and throw the bags.
        </p> -->
      </button>

      <button
        class="selection__item enter-vr"
        @click="$emit('gameModeSelected', false)"
      >
        <h2 class="selection__item__title">FOCUS</h2>
        <img
          class="selection__item__img"
          :src="require('@/assets/img/focus-mode.webp')"
          alt=""
        />
        <!-- <p class="selection__item__description">
          Home mode is a more casual experience. You will be able to move around
          the room and throw the bags.
        </p> -->
      </button>
    </div>
  </div>
</template>

<script>
import heyVR from "../assets/js/heyvrsdk-local.js";
import Scene from "@/components/Scene.vue";

if (process.env.NODE_ENV === "development") {
  // const heyVR = require('../assets/js/heyvrsdk-local.js');
  // console.log(heyVR);
}

export default {
  name: "StartPage",
  components: {
    Scene,
  },
  data() {
    return { gymMode: false };
  },
  methods: {
    testSDK() {
      console.log(heyVR);
      heyVR.leaderboard.postScore("HitsPM", Math.floor(Math.random() * 1000));
      heyVR.leaderboard.get("HitsPM", 10).then((res) => {
        console.log(res);
      });
      // console.log(heyVR.leaderboard.get("HitsPM", 10));
    },
  },
  computed: {
    // isHeadsetConnected() {
    //   return AFRAME.utils.device.checkHeadsetConnected();
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.start-page {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  background-image: url(~@/assets/img/start-page.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &__title {
    font-size: 5rem;
  }

  .selection {
    display: flex;
    justify-content: space-around;

    &__item {
      width: 40%;
      font-family: "capture-it";
      color: white;
      background: rgba(255, 255, 255, 0.153);
      border-radius: 1rem;
      border: 2px solid white;
      padding: 30px;
      cursor: pointer;
      &__title {
        margin: 0;
        font-size: 7rem;
        @media (max-width: 1024px) {
          font-size: 4rem;
        }
      }

      &__img {
        width: 80%;
        @media (max-width: 1200px) {
          width: 90%;
        }
      }
    }
  }
}
</style>