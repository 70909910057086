<template>
  <div id="app">
    <StartPage
      v-if="!gameStarted"
      @gameModeSelected="(gymMode = $event), (gameStarted = true)"
    />
    
    <Scene :gymMode="gymMode" :gameStarted="gameStarted" />
  </div>
</template>

<script>
import StartPage from "./components/StartPage.vue";
import Scene from "./components/Scene.vue";

export default {
  name: "App",
  components: {
    StartPage,
    Scene,
  },
  data() {
    return { gymMode: false, gameStarted: false };
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

@font-face {
  font-family: "capture-it";
  src: url(~@/assets/font/capture-it.ttf);
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
#app {
  font-family: "capture-it";
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
</style>
