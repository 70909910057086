<template>
  <a-entity class="environment">
    <!-- Default lighting injected by A-Frame. -->
    <a-entity light="type: ambient; color: #BBB"></a-entity>
    <a-entity
      light="type: directional; color: #FFF; intensity: 0.4"
      position="1 1 1"
    ></a-entity>

    <a-gltf-model v-if="gymMode" src="objects/gym.glb"></a-gltf-model>

    <!-- <a-box color="black" position="0 5.1 -7.2" height="0.8" width="2.3" depth="0.3">
      <a-entity
        geometry="primitive: plane; height:0.7; width:2"
        material="color: #312927"
        position="0 -0.01 0.25"
        :text="`font: kelsonsans; zOffset: 0.01; width: 9; color: red; align: center; value: ${time}`"
      ></a-entity
    ></a-box> -->

    <!-- <a-image
      rotation="0 90 0"
      position="-7.47 4 2"
      width="10"
      height="3"
      src="img/main-text.webp"
    ></a-image>
     -->
    <!-- <a-image
      rotation="0 90 0"
      position="-7.47 4 2"
      width="10"
      height="3"
      :src="require('@/assets/img/hustle.webp')"
    ></a-image> -->
      <!-- src="img/hustle2.webp" -->

    <a-plane v-if="!gymMode" material="shader: flat" rotation="-90 0 0" width=30 height=30 repeat="30 30" src="img/grid.webp"></a-plane>
    <!-- <a-box
      :width="width"
      :height="10"
      :depth="depth"
      side="double"
      src="objects/walls/wall_color.webp"
      roughness="objects/walls/wall_roughness.webp"
      :normal-scale="wallsTexScale"
      :repeat="wallsTexScale"
      :normal-texture-repeat="wallsTexScale"
    ></a-box> -->

  </a-entity>
</template>

<script>
export default {
  name: "Environment",
  props: ["gymMode"],
  mounted() {
    // this.time = new Date().toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    // setInterval(() => {
    //   this.time = new Date().toLocaleTimeString([], {
    //     hour: "2-digit",
    //     minute: "2-digit",
    //   });
    // }, 10000);
  },
  data() {
    return {
      wallsTexScale: "3 3",
      floorTexScale: "3 3",
      width: 15,
      depth: 15,
      time: 12,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>