console.log('demo heyVR sdk loaded');
const heyVR = (() => {
    const CONFIG = {
        latency: 1000,
        isLoggedIn: false
    }

    const user = (() => {
        function getAmountCoins() {
            return 0;
        }

        function isLoggedIn() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(CONFIG.isLoggedIn)
                }, CONFIG.latency);
            });
        }

        function getName() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    if (CONFIG.isLoggedIn) {
                        resolve("Player1");
                    } else {
                        console.error("heyVR: check whether user is logged in before trying to get the name!");
                        throw new Error('heyVR: check whether user is logged in before trying to get the name!');
                    }
                }, CONFIG.latency);
            });
        }

        return {
            getAmountCoins: getAmountCoins,
            isLoggedIn: isLoggedIn,
            getName: getName,
        }
    })();

    const leaderboard = (() => {
        let dummyLB = [];
        for (let i = 1; i <= 10; i++) {
            dummyLB.push({
                score: 1500 - i * 100,
                user: 'Best Player ' + i,
                created_at: `2023-03-${i.toString().padStart(2, "0")}T03:52:47.000000Z`
            })
        }

        function get(id, numScores) {
            if (numScores) {
                console.warn("heyVR: parameter numScores not effective yet");
            }
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        status: {
                            code: 200,
                            debug: "completed",
                            message: "Highscores for this leaderboard",
                            success: true
                        }, data: dummyLB
                    });
                }, CONFIG.latency);
            });
        }

        function getMy(id, numScores) {
            if (numScores) {
                console.warn("heyVR: parameter numScores not effective yet");
            }
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        status: {
                            code: 200,
                            debug: "completed",
                            message: "Highscores for this leaderboard",
                            success: true
                        }, data: dummyLB
                    });
                }, CONFIG.latency);
            });
        }

        function getFriends(id, numScores) {
            if (numScores) {
                console.warn("heyVR: parameter numScores not effective yet");
            }
            throw new Error("heyVR: Friends not yet implemented");
        }

        function postScore(id, score) {
            if (isNaN(score) || parseInt(Number(score)) != score || isNaN(parseInt(score, 10)) || score < 0) {
                throw new Error("heyVR: score must be positive integer");
            }
            return new Promise((resolve) => {
                setTimeout(() => {
                    if (CONFIG.isLoggedIn) {
                        resolve({
                            data: null,
                            status: {
                                code: 200,
                                debug: "completed",
                                message: "Highscore is lower than previous score.",//or "Highscore Submitted"
                                success: true
                            }
                        });
                    } else {
                        resolve({
                            data: null,
                            status: {
                                code: 401,
                                debug: "err_unauthenticated",
                                message: "You need to be logged in to access this route.",
                                success: false
                            }
                        })
                    }
                }, CONFIG.latency);
            });
        }

        return {
            get: get,
            getMy: getMy,
            getFriends: getFriends,
            postScore: postScore,
        };
    })();

    const inventory = (() => {

        function getCatalog() {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        function get() {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        function purchase(item) {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        return {
            getCatalog: getCatalog,
            get: get,
            purchase: purchase,
        };
    })();

    const saveGame = (() => {

        function all() {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        function wipe() {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        function load(slot) {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        function write(saveData, overwrite, slot) {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        function remove(slot) {
            throw new Error("heyVR: Inventory API not available for local testing yet. Sorry!");
        }

        return {
            all: all,
            wipe: wipe,
            load: load,
            write: write,
            remove: remove
        };
    })();

    return {
        user: user,
        leaderboard: leaderboard,
        inventory: inventory,
        saveGame: saveGame,
    };
})
();
export default heyVR;