<template>
  <a-entity
    class="pear-bag"
    sound="src: #boardHitSpeedbag; volume: 0.1; poolSize: 2"
  >
    <!-- @sound-ended="soundEnded" -->
    <a-box
      rotation="0 90 0"
      :position="`0 ${height} -0.4`"
      color="red"
      id="board"
      width="0.0"
      depth="0.0"
      height="0.0"
      static-body
      visible="true"
      name="board"
      @collide="boardHit"
    >
      <a-circle
        :src="require('@/assets/img/board/color.webp')"
        material="fog: false"
        rotation="90 0 0"
        position="0 -0.16 0"
        body="type: static; shape: none;"
        radius="0.5"
        repeat="3 3"
      ></a-circle>

      <!-- :normal-map="require('@/assets/img/board/normal.webp')"
        :roughness="require('@/assets/img/board/roughness.webp')" -->
      <a-gltf-model
        material="fog: false"
        src="objects/pear-hook.glb"
        scale="0.15 0.15 0.15"
        position="0 -0.5 0"
      ></a-gltf-model
    ></a-box>

    <a-gltf-model
      material="fog: false"
      position="0 0 -0.4"
      src="objects/pear.glb"
      constraint="pivot: 0 0.2 0; targetPivot: 0 -0.2 0; axis: 0 0 1; target: #board; type: hinge;"
      body="type: dynamic; mass: 1; shape: none; linearDamping: 0.0; angularDamping: 0.0"
      shape__main="shape: sphere; radius: 1;"
      scale="0.1 0.1 0.1"
      @collide="bagHit"
      sound="src: #speedbagSmack; volume: 2 ; poolSize: 1"
    >
    </a-gltf-model>

    
    <!-- <a-sphere wasd-controls radius="0.2" static-body position="0 2 2"></a-sphere> -->
  </a-entity>
</template>

<script>
export default {
  name: "PearBag",

  data() {
    return { texScale: "3 3", height: 2.4, playSwing: false };
  },
  methods: {
    soundEnded() {
      // console.log("sound ended");
      this.$refs.swingSound.components.sound.playSound();
    },
    bagHit(e) {
      const elementName = e.detail.body.el.id;
      // console.log(e.detail.body.el.id);
      // console.log(e.target);
      // if (elementName === "board") {
      //   document.getElementById("speedbagSmack").play();
      // } else {
      //   console.log("smah");
      const bag = e.target;
      const force = Math.abs(e.detail.contact.getImpactVelocityAlongNormal());

      const punchStrengthThreshold = 0.001;

      const volume = Math.min(force / 2, 1);

      if (force > punchStrengthThreshold) {
        const volume = Math.min(force / 2, 1);
        // bag.components.sound.data.volume = volume;
        bag.setAttribute("sound", "volume", force * 2);

        // console.log(e.target.components.sound.data.volume);
        bag.components.sound.playSound();
      }
      // }
    },
    boardHit(e) {
      // console.log(e);

      const bagParent = e.target.parentNode;
      const force = Math.abs(e.detail.contact.getImpactVelocityAlongNormal());

      // console.log(force);
      const punchStrengthThreshold = 0.001;

      const volume = Math.min(force / 2, 1);

      if (force > punchStrengthThreshold) {
        const volume = Math.min(force / 2, 1);
        // bag.components.sound.data.volume = volume;
        bagParent.setAttribute("sound", "volume", force * 2);

        bagParent.components.sound.playSound();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>