AFRAME.registerComponent("ca-leaderboard", {
    schema: {
      /** Construct Arcade SDK leaderboard id */
      leaderboardId: { type: "string", default: "" },
      /** Font file */
      fontSrc: { type: "string", default: "" },
      /** Text color */
      textColor: { type: "color", default: "#FFF" },
      /** Text font size */
      textWidth: { type: "number", default: 1.5 },
      /** Horizontal spacing between rank, name and score columns */
      horizontalSpacing: { type: "number", default: 0 },
      /** Vertical spacing between leaderboard entries */
      verticalSpacing: { type: "number", default: 0 },
      /**
       * The leaderboards only store integer values. To achieve floating point values
       * for currencies and time, you can set a multiplier here to divide on retrieve
       * and multiply on set
       */
      scoreStorageMultiplicator: { type: "number", default: 1.0 },
      /** Text to display above the leaderboard table */
      title: { type: "string", default: "Top earning coffee houses:" },
      /** Display debug data */
      debug: { type: "boolean", default: true },
    },
  
    /**
     * @summary Submit score.
     *
     * Opens popup for user to login if not logged in already.
     */
    submitScore: function(score) {
      /* Math.floor seems necessary to guarantee integral value */
      if ("casdk" in window) {
        let submissionScore = Math.round(score * this.data.scoreStorageMultiplicator);
        return casdk
          .submitScore(this.data.leaderboardId, submissionScore)
          .then(() => {
            console.log("Score submitted");
            if(this.el.getAttribute('text__login'))
              this.el.removeAttribute('text__login');
            /* Give the server some time to update */
            setTimeout(this.getLeaderboard.bind(this), 2000);
          });
      }
    },
  
    /**
     * @summary Request an update of the leaderboard entries.
     *
     * This is already done after @ref submitScore()
     */
    getLeaderboard: function() {
      if (!("casdk" in window)) {
          if (this.data.debug) {
              this.el.setAttribute('text__login', {
                value: "\n\n\n\n\n\n(login to submit score on game over)",
                color: this.data.textColor,
                font: this.data.fontSrc,
                width: this.data.textWidth/2,
                align: "center",
                baseline: "top",
                lineHeight: this.data.verticalSpacing*2,
              });
              /* Simulate a delayed request return */
              setTimeout(() => {
                  const s = this.data.scoreStorageMultiplicator;
                  this.onScoresRetrieved([
                      {rank: 0, displayName: "User", score: 100*s},
                      {rank: 1, displayName: "Player", score: 78*s},
                      {rank: 2, displayName: "Bot", score: 34*s},
                      {rank: 3, displayName: "Debugger", score: 12*s},
                      {rank: 4, displayName: "Test", score: 2*s},
                  ]);
              }, 1000);
          }
          return;
      }
      casdk.getLeaderboard(this.data.leaderboardId).then(r => {
        console.log(r.leaderboard);
        this.onScoresRetrieved(r.leaderboard);
      });
      if(!casdk.isLoggedIn()) {
          this.el.setAttribute('text__login', {
            value: "\n\n\n\n\n\n(login to submit score on game over)",
            color: this.data.textColor,
            font: this.data.fontSrc,
            width: this.data.textWidth/2,
            align: "center",
            baseline: "top",
            lineHeight: this.data.verticalSpacing*2,
          });
      }
    },
  
    onScoresRetrieved: function(scores) {
      if (scores == null) {
        console.warn("Retrieving scores failed.");
        return;
      }
  
      let leftText = "\n";
      let centerText = this.data.title + "\n";
      let rightText = "\n";
  
      for (let i = 0; i < Math.min(5, scores.length); i++) {
        const rank = (scores[i].rank + 1) + "\n";
        const name = (scores[i].displayName || "unknown") + "\n";
        const score = (scores[i].score / this.data.scoreStorageMultiplicator) + "\n";
  
        leftText += rank;
        centerText += name;
        rightText += score;
      }
  
      this.el.setAttribute("text__left", "value", leftText);
      this.el.setAttribute("text__center", "value", centerText);
      this.el.setAttribute("text__right", "value", rightText);
      this.el.object3D.visible = true;
      console.log("Leaderboard updated");
    },
    init: function() {
      this.loaded = false;
  
      this.el.setAttribute("text__left", {
        color: this.data.textColor,
        font: this.data.fontSrc,
        width: this.data.textWidth,
        align: "left",
        baseline: "top",
        xOffset: -this.data.horizontalSpacing,
        lineHeight: this.data.verticalSpacing,
      });
  
      this.el.setAttribute("text__center", {
        color: this.data.textColor,
        font: this.data.fontSrc,
        width: this.data.textWidth,
        align: "center",
        baseline: "top",
        lineHeight: this.data.verticalSpacing,
      });
  
      this.el.setAttribute("text__right", {
        color: this.data.textColor,
        font: this.data.fontSrc,
        width: this.data.textWidth,
        align: "right",
        baseline: "top",
        xOffset: this.data.horizontalSpacing,
        lineHeight: this.data.verticalSpacing,
      });
      console.log(' i am the leaderboard');
  
      if ("casdk" in window || this.data.debug) {
        if (!this.loaded) {
          this.loaded = true;
          this.getLeaderboard(this.data.leaderboardId);
        }
      }
    },
  });