<template>
  <a-entity class="score">
    <a-image
      width="2"
      height="1.5"
      src="img/background.svg"
      rotation="0 30 0"
      position="-2.2 1.6 -2.2"
      class="scoreboard"
    >
      <!-- <a-entity
        material="fog: false"
        position="0 0.3 0.1"
        ca-leaderboard="leaderboardId: HitsPM; textColor: black;"
      ></a-entity> -->
      <!-- <a-entity
        material="fog: false"
        position="0 0.3 0.1"
        :canvas-text="`text: ${leaderboard}`"
      ></a-entity> -->
      <a-entity
        material="fog: false"
        position="0 0.3 0.1"
        :text="`font: kelsonsans; zOffset: 0.01; width: 7; color: black; align: center; value: SCORE: ${hits}`"
      ></a-entity>
      <a-entity
        material="fog: false"
        position="0 -0.3 0.1"
        :text="`font: kelsonsans; zOffset: 0.01; width: 7; color: black; align: center; value: HPM: ${hpm}`"
      ></a-entity>
      <a-entity
        material="fog: false"
        position="0 -0.5 0.1"
        :text="`font: kelsonsans; zOffset: 0.01; width: 2.3; color: black; align: center; value:  Quickest: ${hpmHighScore}`"
      ></a-entity>
    </a-image>

    <a-plane
      rotation="0 0 0"
      position="2 1.5 -4.2"
      class="leaderboard"
      material="opacity: 0.8; color: black"
      geometry="primitive: plane"
      width="1.2"
      height="1.5"
    >
      <a-image
        class="title"
        color="white"
        :src="require('@/assets/img/titles/leaderboard_title.webp')"
        position="0 0.65 0.02"
        scale="1.2 0.2 1"
      ></a-image>

      <a-entity
        material="fog: false"
        position="0 -0.05 0.1"
        :text="`font: kelsonsans; zOffset: 0.01; width: 2; color: white; align: center; value: ${leaderboard}`"
      ></a-entity>
    </a-plane>

    <a-plane
      rotation="0 0 0"
      class="scoreboard"
      material="opacity: 0.8; color: black"
      position="0 1.5 -2.2"
      geometry="primitive: plane"
      width="1.2"
      height="1.5"
    >
      <!-- Add Timer Display -->
      <a-entity
        material="fog: false"
        position="0 0.1 0.1"
        :text="`font: kelsonsans; zOffset: 0.01; width: 7; color: black; align: center; value: TIME: ${timeLeft}`"
      ></a-entity>

      <!-- Add Velocity Display -->
      <a-entity
        material="fog: false"
        position="0 -0.7 0.1"
        :text="`font: kelsonsans; zOffset: 0.01; width: 2.3; color: black; align: center; value:  Velocity: ${velocity}`"
      ></a-entity>
    </a-plane>
  </a-entity>
</template>

<script>
import heyVR from "../assets/js/heyvrsdk-local.js";

export default {
  name: "Score",
  props: ["hits"],
  mounted() {
    console.log(heyVR);
    heyVR.leaderboard
      .get("HitsPM", 10)
      .then((res) => {
        console.log(res);
        let leaderboardPrep = "";
        res.data.map((item) => {
          leaderboardPrep += `${item.user}: ${item.score} \n`;
        });
        this.leaderboard = leaderboardPrep;
      })
      .catch((err) => {
        console.log(err);
      });


      this.startGame();
  },
  watch: {
    hits: function () {
      this.onHit();
      this.currentHitsForHPM++;
      if (this.hpm > this.hpmHighScore) {
        this.hpmHighScore = this.hpm;
        heyVR.leaderboard.postScore("HitsPM", this.currentHitsForHPM);
      }
    },
  },
  methods: {
    startGame() {
      this.resetGame();
      this.gameInProgress = true;
      this.startTimer();
    },
    resetGame() {
      this.hits = 0;
      this.hpm = 0;
      this.timeLeft = 30;
      this.currentHitsForHPM = 0;
    },
    startTimer() {
      const timerInterval = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft <= 0) {
          clearInterval(timerInterval);
          this.gameInProgress = false;
          this.calculateHPM();
        }
      }, 1000);
    },
    calculateHPM() {
      this.hpm = Math.round((this.hits / 30) * 60);
      if (this.hpm > this.hpmHighScore) {
        this.hpmHighScore = this.hpm;
        heyVR.leaderboard.postScore("HitsPM", this.hpm);
      }
    },
    onHit(e) {
      if (this.gameInProgress) {
        // this.hits++;
        this.velocity = e.detail.velocity;
      }
    },
  },
  data() {
    return {
      currentHitsForHPM: 0,
      hpm: 0,
      hpmHighScore: 0,
      leaderboard: null,
      gameInProgress: true,
      timeLeft: 30,
      velocity: 0,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>