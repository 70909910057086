<template>
  <a-entity class="standing-bag" scale="0.7 0.7 0.7">
    <a-cylinder
      rotation="0 0 0"
      position="0 -0.5 -1"
      color="red"
      id="other-box"
      radius="0.5"
      static-body
      visible="false"
    ></a-cylinder>
    <a-gltf-model
      position="0 -0.5 -1"
      src="objects/bag-bottom.glb"
    ></a-gltf-model>
    <a-gltf-model
      scale="0.9 0.9 0.9"
      crossorigin="anonymous"
      constraint="pivot: 0 0.5 0; targetPivot: 0 0.5 0; axis: 1 0 1; target: #other-box; type: pointToPoint"
      color="green"
      positon="0 0 -1"
      rotation="0 0 0"
      wireframe="false"
      dynamic-body="mass: 10000; shape: cylinder;"
      src="objects/bag.glb"
    ></a-gltf-model>
    <!-- <a-cylinder
      constraint="pivot: 0 1 0; targetPivot: 0 0.5 0; axis: 1 0 1; target: #other-box; type: pointToPoint"
      segments-height="1"
      segments-radial="20"
      wireframe="false"
      src="objects/bag/bag_color.webp"
      normal-map="objects/bag/bag_normal.webp"

      :normal-scale="texScale"
      :normal-texture-repeat="texScale"
      :repeat="texScale"
      
      rotation="0 0 0"
      visible="true"
      height="2"
      radius="0.5"
      dynamic-body="mass: 10000"
    ></a-cylinder> -->
  </a-entity>
</template>

<script>
export default {
  name: "StandingBag",

  data() {
    return { texScale: "3 3" };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>